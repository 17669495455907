import { applyStylesIf, cx } from 'utils/cx'

import { Countdown } from '../../Countdown/Countdown'
import {
	CLIENT_LINK_TYPE,
	ClientTextLink,
	type ClientTextLinkProps,
} from '../../TextLink/client'
import {
	type COMM_BANNER_BG_COLOR_TYPE,
	COMM_BANNER_TYPE,
	type CommElement,
} from '../types'
import { isValidColor } from '../utils'
import { BannerTitles } from './BannerTitles/BannerTitles'
import { ClosableWrapper } from './ClosableWrapper/ClosableWrapper'

import styles from './CommBanner.module.scss'
import text from 'fukku/styles/texts.module.scss'

export interface CommBannerProps {
	readonly id: string
	readonly type: COMM_BANNER_TYPE
	readonly title?: string
	readonly discount?: string
	readonly subtitle?: string
	readonly subtitlesGroup?: {
		titleKey: string
		subtitleKey: string
	}[]
	readonly countdown?: string
	readonly maxDays?: number
	readonly links?: ClientTextLinkProps[]
	readonly infoText?: string
	readonly infoIconLabel?: string
	readonly closable?: boolean
	readonly closeButtonLabel?: string
	readonly className?: string
	readonly onShow?: (props: CommElement) => void
	readonly inverse?: boolean
	readonly bgColorType?: COMM_BANNER_BG_COLOR_TYPE
	readonly bgColorCode?: string
}

interface CommBannerCarouselItemProps extends CommBannerProps {
	readonly isHiddenInCarousel?: boolean
	readonly infoIconButtonCarouselClassName?: string
	readonly hasArrows?: boolean
}

const getClosableWrapperClassName = ({
	showInfoTooltip,
	hasArrows = false,
	hasLinks = false,
	className,
}: {
	showInfoTooltip: boolean
	hasArrows?: boolean
	hasLinks?: boolean
	hasOnlyOneLink?: boolean
	className?: string
}) =>
	cx(
		styles.wrapper,
		applyStylesIf(showInfoTooltip, styles.withInfo),
		applyStylesIf(hasArrows, styles.withArrows),
		applyStylesIf(hasLinks, styles.withLinks),
		className
	)

export function CommBanner({
	id,
	type,
	title,
	discount,
	subtitle,
	subtitlesGroup,
	countdown,
	maxDays,
	links,
	infoText,
	infoIconLabel,
	closable = false,
	closeButtonLabel,
	className,
	onShow,
	inverse,
	bgColorType,
	bgColorCode,
	infoIconButtonCarouselClassName,
	isHiddenInCarousel,
	hasArrows,
}: CommBannerCarouselItemProps): React.ReactNode {
	const isPromoBanner = type === COMM_BANNER_TYPE.PROMO
	const hasLinks = links && links.length > 0
	const hasOnlyOneLink = links && links.length === 1
	const isInverse = isValidColor(bgColorType, bgColorCode) && inverse
	const tabIndex = isHiddenInCarousel ? -1 : undefined
	const showInfoTooltip = Boolean(isPromoBanner && infoText)
	const closableWrapperClassName = getClosableWrapperClassName({
		hasArrows,
		hasLinks,
		showInfoTooltip,
		hasOnlyOneLink,
		className,
	})

	return (
		<ClosableWrapper
			id={id}
			onShow={onShow}
			isHiddenInCarousel={isHiddenInCarousel}
			isClosable={!isPromoBanner && closable}
			closeButtonLabel={closeButtonLabel}
			isInverse={isInverse}
			bgColorType={bgColorType}
			bgColorCode={bgColorCode}
			className={closableWrapperClassName}
			showInfoTooltip={showInfoTooltip}
			infoText={infoText}
			infoIconLabel={infoIconLabel}
			infoIconButtonCarouselClassName={infoIconButtonCarouselClassName}
			firstLink={hasOnlyOneLink ? links[0] : undefined}
		>
			<div className={styles.titlesCountdownWrapper}>
				<BannerTitles
					title={title}
					subtitle={subtitle}
					subtitlesGroup={subtitlesGroup}
					discount={discount}
					isPromoBanner={isPromoBanner}
				/>
				{countdown && (
					<Countdown
						date={countdown}
						maxDays={maxDays}
						className={cx(text.bodySRegular, styles.countdown)}
						numbersClassName={styles.countdownNumbers}
					/>
				)}
			</div>
			{hasLinks && (
				<ul className={styles.links}>
					{links.map(
						({ href, linkProps, children, ...props }: ClientTextLinkProps) => (
							<li key={`${id}-${href}`} className={styles.link}>
								{hasOnlyOneLink ? (
									<span className={styles.ctaNoLink}>{children}</span>
								) : (
									<ClientTextLink
										href={href}
										type={CLIENT_LINK_TYPE.INSPIRATIONAL}
										inverse={isInverse}
										linkProps={{
											...linkProps,
											tabIndex,
											title: linkProps?.title ?? (children as string),
										}}
										className={styles.ctaLink}
										{...props}
									>
										{children}
									</ClientTextLink>
								)}
							</li>
						)
					)}
				</ul>
			)}
		</ClosableWrapper>
	)
}
