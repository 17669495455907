import type { RefObject } from 'react'
import { cx } from 'utils/cx'
import { ClientLink } from 'utils/link/client'

import type { ClientTextLinkProps } from '../../../TextLink/client'

import styles from './CommBannerLinkWrapper.module.scss'

interface CommBannerLinkWrapperProps {
	readonly tabIndex: -1 | undefined
	readonly commRef: RefObject<HTMLDivElement>
	readonly colorStyle: {}
	readonly wrapperClassName: string
	readonly children: React.ReactNode
	readonly firstLink?: ClientTextLinkProps
}
export function CommBannerLinkWrapper({
	tabIndex,
	commRef,
	colorStyle,
	wrapperClassName,
	children,
	firstLink,
}: CommBannerLinkWrapperProps) {
	const hasOnlyOneLink = firstLink && firstLink.href && firstLink.children

	return hasOnlyOneLink ? (
		<div ref={commRef}>
			<ClientLink
				{...firstLink}
				linkProps={{
					...firstLink.linkProps,
					title: firstLink.children as string,
					className: cx(wrapperClassName, styles.noUnderline),
					style: colorStyle,
					tabIndex,
				}}
				href={firstLink?.href}
			>
				{children}
			</ClientLink>
		</div>
	) : (
		<div ref={commRef} className={wrapperClassName} style={colorStyle}>
			{children}
		</div>
	)
}
